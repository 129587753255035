import "./meeting.css";
import photo from "../Assets/1.jpg"

export default function Meeting(props) {

  return (
    <div className={props.size === "sm" ? "meeting sm" : "meeting"} id="Meeting">
      <h2>Introduction to Manufactured Housing Communities</h2>
      <span className="Date"> SCALE & DEMAND: Over 22 million Americans live in mobile homes, with demand continuing to increase as post-pandemic rents have skyrocketed</span>
      <span className="Date"> RECESSION RESISTANT ASSET CLASS: In a downturn mobile home parks are seen as a cheaper housing alternative</span>
      <span className="Date"> LOW TURNOVER: Turnover rate is traditionally much less than that of multifamily due to the high costs of moving a mobile home</span>
      <span className="Date"> HIGHEST CAP RATES: Mobile home parks have the highest yields in commercial real estate with cap rates often over 10%.</span>
      <img src={photo} alt="photo" />
    </div>
  );
}
