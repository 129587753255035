import { Homepage } from "./components/homepage/homepage";
import './App.css';

function App() {
  return (
    <div className="homepage">
    <Homepage />
   </div> );
}

export default App;
