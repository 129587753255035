import { useState, useEffect } from "react";
import './homepage.css';
import Navbar from '../navbar/navbar';
import About from '../about/about';
import Contact from '../contact/contact';
import Meeting from '../meeting/meeting';
import Summary from '../summary/summary';
import { FaFacebook, FaPhone } from "react-icons/fa";

export function Homepage() {
  const [media, setMedia] = useState('');

  useEffect(() => {
    const mediaQueries = [
      { size: 'reg', query: window.matchMedia("only screen and (min-width: 768px) ") },
      { size: 'sm', query: window.matchMedia("only screen and (max-width: 768px)") }
    ];

    const match = mediaQueries.find(entry => entry.query.matches);
    setMedia(match.size);

    function handleChange(e) {
      const update = mediaQueries.find(entry => entry.query.matches);
      setMedia(update.size);
    }
    match.query.addEventListener("change", handleChange);
    return () => {
      match.query.removeEventListener("change", handleChange);
    };
  }, [media]);

  return (
    <>
      <div className='homepage'>
        <Navbar size={media} />
        <Meeting size={media} />
        <Summary size={media} />
        <About size={media} />
        <Contact size={media} />

      </div>
      {/* <div className="footer">
        <div className="footer-box">
          <p>Designed by <a href="https://awc-home.vercel.app/">AWC</a></p>
        </div>

      </div> */}
    </>

  );
}