import "./contact.css";

export default function Contact(props) {

  return (
    <div className={ props.size === "sm" ? "contact sm" : "contact" } id="Contact">
      <p>Lzito3000@gmail.com</p>
      <p>CONTACT INFO</p>
      <p>917-692-3049</p>
    </div>
  );
}
