export const cardArray = [
  {
    header: "White Birch",
    text: "Well community in Dallas, PA consisting of 74 spaces situated on 23.5 acres along Route 309."
  },
  {
    header: "Country Pine Estates",
    text: "Well maintained community consisting of 49 spaces situated on 15.5 acres."
  }
];
