import "./summary.css";
import photo from "../Assets/trailer_park.jpg"

export default function Summary(props) {
    return (
        <div>
            <div className="abtUs">
                <div className="summary">
                    <h2>About Us</h2>
                    <p>Lucien Zito is the Managing Partner of the investment vehicle.
                        He has over 20 years of experience in the real estate industry. Most
                        recently, he started a value-add Manufactured Home Park real estate
                        investment company, currently with 3 parks under management. Prior to that
                        he scaled Aulder Capital from zero assets to over $500M in AUM. Prior to
                        his involvement in Aulder Capital, Mr. Zito was a Mall Operations Manager
                        with Macerich, over-seeing one of their premier retail properties. Prior to his
                        time at Macerich, Mr. Zito worked for Vornado Realty Trust where he
                        managed daily operations and tenant/landlord construction in one of their
                        largest retail malls. Along with managing various departments at the
                        property, he was also responsible for maintaining strict compliance with all
                        NYC agencies. Earlier in his career, Mr. Zito was both a construction
                        supervisor for Pavarini McGovern, where he supervised the construction of
                        luxury residential and hotel properties, and a property manager for Urban
                        American. At Urban American he managed over 1,100 residential units in
                        Brooklyn and led both a violations abatement team and a construction team
                        focused on residential renovations. He holds a bachelor’s degree in
                        Hospitality Management from Cornell University.</p>
                </div>
                <img src={photo} alt="photo" />
            </div>
            <div className="addproj">
            <h2>Existing Portfolios</h2>
            </div>
        </div>
    );

}
